/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #GLOBAL CLASSES
\*------------------------------------*/


//colors
.color-trans { color: $transblau; }
.color-white { color: $weiss; }
.color-black { color: $schwarz; }
.color { color: $farbe; }


//border
.radius { border-radius: $global-radius; }
.round { border-radius: $global-rounded; }


//text
.align-left { text-align: left; }
.align-right { text-align: right; }
.align-center { text-align: center; }
.text-justify { text-align: justify; }
.text-indent {
	text-indent: $padding;
	.label, a { text-indent: 0; }
}


//block
.float-left { float: left; }
.float-right { float: right; }
.clear { @include clearfix; }
.expand { display: block; }
.full-width { width: 100%; }


//visibility
.is-hidden, .hidden, .hide {
	//Completely remove from the flow and screen readers
	@include hidden;
}
.is-vishidden {
	//Completely remove from the flow but leave available to screen readers
    @include visuallyhidden;
}












