/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/


//layout
.v-padding { @include v-padding($layout-v-padding); }
.h-padding { @include h-padding($layout-h-padding); }


//jumpnav
.jumpnav { @include visuallyhidden; }



//all
.all {}


//header
.header {
	position: relative;
	padding: $layout-v-padding $layout-h-padding;
}


//main
.main {
	//background-color: $weiss;
	.noscript {
		text-align: center;
		padding: 0 2em;
	}
}


//footer
.footer {
	padding: 50vh $layout-h-padding;
}
.epilogue p {
	margin-bottom: 105vh;
	@extend .h4;
	text-align: center;
	//opacity: 0;
	&:last-child { margin-bottom: -0.5em; }
}





/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/


/* all > $all-range: (0em, 29.9375em) (0, 479px)
****************************************************/

@media #{$all-up} {}

@media #{$all-only} {}



/* tiny > $tiny-range: (30em, 39.9375em) (480px, 639px)
****************************************************/

@media #{$tiny-up} {
	//layout
	//header
	//main
	//footer
}

@media #{$tiny-only} {}



/* small > $small-range: (40em, 47.9375em) (640px, 767px)
****************************************************/

@media #{$small-up} {
	//layout
	//header
	//main
	//footer
}

@media #{$small-only} {}



/* normal > $normal-range: (48em, 63.9375em) (768px, 1023px)
****************************************************/

@media #{$normal-up} {
	//layout
	html, body { font-size: $body-font-size; }
	
	//header
	//main
	//footer
}

@media #{$normal-only} {
}



/* medium > $medium-range: (64em, 79.9375em) (1024px, 1279px)
****************************************************/

@media #{$medium-up} {
	//layout
	html, body { font-size: $body-font-size-medium-desktop; }
	
	//header
	//main
	//footer
}

@media #{$medium-only} {}



/* large > $large-range: (80em, 89.9375em) (1280px, 1439px)
****************************************************/

@media #{$large-up} {
	//layout
	html, body { font-size: $body-font-size-large-desktop; }
	
	//header
	//main
	//footer
}

@media #{$large-only} {}



/* xlarge > $xlarge-range: (90em, 99.9375em) (1440px, 1599px)
****************************************************/

@media #{$xlarge-up} {
	//layout
	//header
	//main
	//footer
}

@media #{$xlarge-only} {}



/* xxlarge > $xxlarge-range: (100em, 119.9375em) (1600px, 1919px)
****************************************************/

@media #{$xxlarge-up} {
	//layout
	//header
	//main
	//footer
}
@media #{$xxlarge-only} {}



/* xxxlarge > $xxlarge-range: (120em) (1920px)
****************************************************/

@media #{$xxxlarge-up} {
	//layout
	html, body { font-size: $body-font-size-xxxlarge-desktop; }
	
	//header
	//main
	//footer
}



/* landscape
****************************************************/
@media #{$landscape} {}


/* portrait
****************************************************/
@media #{$portrait} {}


/* retina
****************************************************/
@media #{$retina} {}







