/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #CARDS
\*------------------------------------*/


.cards {
	display: none;
	z-index: 10;
	@include list-plain;
}

.card {
	position: relative;
	width: 100%;
	overflow: hidden;
	background-color: $schwarz;
	//@include transition(all ($transition-duration) ease-in-out);
	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

.card-background {
	position: absolute;
	width: 100%;
	height: 100%;
	@include transition(all ($transition-duration) ease-in-out);
	img {
		position: absolute;
		width: 100%;
		margin: 0;
		opacity: 0;
		//@include transform(scale(0.9));
		@include filter(hue-rotate(0deg) saturate(0%));
		@include transition(all $transition-duration ease-in-out);
		&.status-loaded {
			opacity: 1;
			@include filter(hue-rotate(0deg) saturate(100%));
			//@include transform(scale(1));
		}
	}
}
.card-content {
	position: absolute;
	//width: 100%;
	height: 100%;
	padding: $layout-h-padding;
	> div { @include transition(all $transition-duration ease-in-out); }
	.card-content-hidden { opacity: 0; }
	.card-title {
		h1, h2, h3, h4 {
			@include font-bold;
			font-size: $h4-font-size;
			margin: 0;
		}
		p { margin: 0; }
	}
	
	.card-more {
		margin-top: $margin-bottom / 2;
		@include linkcolor($farbe, $weiss);
		.link-more {
			&:before {
				margin-right: 0;
				font-size: $h4-font-size;
			}
		}
	}
	
	.card-tags {
		margin-top: $margin-bottom / 2;
		.list-inline {
			margin-bottom: $layout-h-padding;
			li {
				padding: 0 ($padding / 2) 0 0;
				font-size: $small-font-size;
			}
		}
		@include linkcolor($weiss, $farbe);
		a {
			&:link, &:visited {
				margin: 0;
				padding: 0.1em 0.2em;
				background-color: rgba($weiss, 0.25);
			}
			&:hover { background-color: $weiss; }
		}
	}
}

.card {
	&.card-image {}
	&.card-text {
		.card-content { opacity: 1; }
	}
	&.card-color-schwarz { background-color: $schwarz; }
	&.card-color-farbe {
		background-color: $blau;
		.card-more { @include linkcolor($schwarz, $weiss); }
		.card-tags {
			@include linkcolor($weiss, $farbe);
			a {
				&:link, &:visited { background-color: rgba($schwarz, 0.1);}
				&:hover { background-color: $weiss; }
			}
		}
	}
	&.card-color-gruen {
		background-color: $gruen;
		.card-more { @include linkcolor($schwarz, $weiss); }
		.card-tags {
			@include linkcolor($weiss, $gruen);
			a {
				&:link, &:visited { background-color: rgba($schwarz, 0.1);}
				&:hover { background-color: $weiss; }
			}
		}
	}
	&.card-color-pink {
		background-color: $pink;
		.card-more { @include linkcolor($schwarz, $weiss); }
		.card-tags {
			@include linkcolor($weiss, $pink);
			a {
				&:link, &:visited { background-color: rgba($schwarz, 0.1);}
				&:hover { background-color: $weiss; }
			}
		}
	}
	&.card-color-orange {
		background-color: $orange;
		.card-more { @include linkcolor($schwarz, $weiss); }
		.card-tags {
			@include linkcolor($weiss, $orange);
			a {
				&:link, &:visited { background-color: rgba($schwarz, 0.1);}
				&:hover { background-color: $weiss; }
			}
		}
	}
	&.card-color-nv {
		background-color: $nv;
		.card-more { @include linkcolor($schwarz, $weiss); }
		.card-tags {
			@include linkcolor($weiss, $nv);
			a {
				&:link, &:visited { background-color: rgba($schwarz, 0.1);}
				&:hover { background-color: $weiss; }
			}
		}
	}
	&.card-color-weiss {
		@include textcolor($schwarz);
		background-color: $weiss;
		.card-more { @include linkcolor($farbe, $schwarz); }
		.card-tags {
			@include linkcolor($schwarz, $weiss);
			a {
				&:link, &:visited { background-color: rgba($schwarz, 0.1);}
				&:hover { background-color: $farbe; }
			}
		}
	}
	
	&:hover {
		.card-background {
			img {
				opacity: 0.5;
				@include filter(hue-rotate(0deg) saturate(200%));
			}
		}
		.card-content {
			.card-content-hidden { opacity: 1; }
		}
	}
}

.card-tiny { width: 50% }
.card-small {}
.card-large {}
.card-wide {
	.card-background {
		width: 200%;
		img { left: -25%; }
	}
}
.card-high {
	.card-background {
		img { top: -50%; }
	}
}
.card-full {
	.card-background {
		width: 200%;
		img { left: -25%; }
	}
}


@media #{$tiny-up} {
	.card-tiny { width: 25% }
	.card-small { width: 50%; }
	.card-high { width: 50%; }
}

@media #{$normal-up} {
	.card-content { padding: $layout-h-padding / 1.5; }
	
	.card-tiny { width: calc(100% / 6); }
	.card-small { width: calc(100% / 3); }
	.card-large { width: calc(100% / 3 * 2); }
	.card-wide {
		width: calc(100% / 3 * 2);
		&:after { padding-bottom: 50%; }
		.card-background {
			width: 100%;
			img { left: 0%; }
		}
	}
	.card-high {
		width: calc(100% / 3);
		&:after { padding-bottom: 200%; }
		.card-background {
			img { top: 0%; }
		}
	}
	.card-full {
		&:after { padding-bottom: 50%; }
		.card-background {
			width: 100%;
			img { left: 0%; }
		}
	}
}

@media #{$medium-up} {
	.card-background {
		img { @include transition(all ($transition-duration * 4) cubic-bezier(.25,0,0,1)); }
	}
	.card-content {
		padding: $layout-h-padding / 2;
		.card-title {
			h1, h2, h3, h4 { font-size: $h5-font-size; }
		}
		.card-tags {
			.list-inline {
				margin-bottom: $layout-h-padding / 2;
			}
		}
	}
	.card {
		&:hover {
			.card-background {
				img {
					@include transition(all ($transition-duration * 4) cubic-bezier(.25,0,0,1));
					@include transform(scale(1.2));
					@include filter(hue-rotate(180deg) saturate(100%));
				}
			}
		}
	}
	
	.card-tiny { width: calc(25% / 2); }
	.card-small { width: 25%; }
	.card-large { width: 50%; }
	.card-wide { width: 50%; }
	.card-high { width: 25%; }
}

@media #{$large-up} {
	
}

@media #{$xlarge-up} {
	.card-content { padding: $layout-h-padding / 2.5; }
	
	.card-tiny { width: 10%; }
	.card-small { width: 20%; }
	.card-large { width: 40%; }
	.card-wide { width: 40%; }
	.card-high { width: 20%; }
}

@media #{$xxxlarge-up} {
	.card-content { padding: $layout-h-padding / 3; }
	
	.card-tiny { width: calc(100% / 12); }
	.card-small { width: calc(100% / 6); }
	.card-large { width: calc(100% / 6 * 2); }
	.card-wide { width: calc(100% / 6 * 2); }
	.card-high { width: calc(100% / 6); }
}




