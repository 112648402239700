/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #FILTER
\*------------------------------------*/


.filter {
	margin: 0;
	ul {
		margin: 0 (-$padding) 0 (-$padding / 2);
		li {
			@include font-bold;
			visibility: hidden;
			padding: 0;
		}
		a {
			&:link, &:visited {
				padding: $padding / 2;
				color: $weiss;
			}
			&:hover, &:active {
				color: $farbe;
			}
		}
		.filter-headline {
			@include font-normal;
			padding: $padding / 2;
		}
		.filter-active a { color: $farbe; }
	}
}


