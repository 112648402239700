/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #LOGO
\*------------------------------------*/


.logoclaim, .t {
	z-index: 5000;
	@include ir();
	margin: 0;
	line-height: 1;
	a {
		&:link, &:visited {
			position: relative;
			display: block;
		}
	}
}

//width 765, height 158
$logoclaim-width: 183px; $logoclaim-height: ceil(159*$logoclaim-width/765);

$logoclaim-normal-width: 195px; $logoclaim-normal-height: ceil(159*$logoclaim-normal-width/765);
$logoclaim-medium-width: 245px; $logoclaim-medium-height: ceil(159*$logoclaim-medium-width/765);
$logoclaim-large-width: 245px; $logoclaim-large-height: ceil(159*$logoclaim-large-width/765);

$logoclaim-width-hover: 100vw; $logoclaim-height-hover: 50vw;

.logoclaim {
	display: none;
	position: relative;
	top: 0;
	left: -0.2vw;
	width: $logoclaim-width;
	height: $logoclaim-height;
	max-height: 50vh;//66.66667vh;
	margin: 0 0 $layout-v-padding 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
	@include rollout;
	&.status-not-animateable { background-image: url(../images/pro/transporter.svg); }
	&.status-animateable:hover {
		width: $logoclaim-width-hover;
		height: $logoclaim-height-hover;
		top: -$layout-v-padding;
		left: -$layout-h-padding;
		margin-bottom: 0;
	}
	@media #{$normal-up} {
		width: $logoclaim-normal-width;
		height: $logoclaim-normal-height;
	}
	@media #{$medium-up} {
		width: $logoclaim-medium-width;
		height: $logoclaim-medium-height;
	}
}


.est {
	z-index: 5000;
	display: none;
	position: absolute;
	top: $layout-v-padding;
	right: $layout-h-padding;
	margin: 0;
	line-height: 1;
	@include linkcolor($weiss, $farbe);
	span:last-child { display: none; }
	@media #{$normal-up} {
		span:first-child { display: none; }
		span:last-child {
			display: inline;
			//@include font-bold;
			//font-size: $small-font-size;
			//text-transform: uppercase;
		}
	}
}
/*
//width 462, height 60
$est-width: 75px; $est-height: ceil(60*$est-width/462);
$est-normal-width: 100px; $est-normal-height: ceil(60*$est-normal-width/462);
.est {
	display: none;
	position: absolute;
	top: $layout-v-padding;
	right: $layout-h-padding;
	width: $est-width;
	height: $est-height;
	@include transform(scale(1));
	@include rollout;
	a {
		&:link, &:visited {
			width: $est-width;
			height: $est-height;
			background-image: url(../images/pro/est.png);
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 100% 100%;
		}
	}
	&:hover {
		@include transform(scale(1.1));
		@include rollover;
	}
	@media #{$normal-up} {
		width: $est-normal-width;
		height: $est-normal-height;
		a {
			&:link, &:visited {
				width: $est-normal-width;
				height: $est-normal-height;
			}
		}
	}
	&.status-hidden { right: -$est-normal-width; }

}
*/


//width 977, height 904
$t-width: 30vw; $t-height: round(904*$t-width/977);
$t-width-small: 20vw; $t-height-small: round(904*$t-width-small/977);
$t-max-width: 250px; $t-max-height: round(904*$t-max-width/977);

.t {
	visibility: hidden;
	opacity: 0;
	position: fixed;
	top: $layout-v-padding;
	left: $layout-h-padding;
	max-height: $t-max-height;
	max-width: $t-max-width;
	height: $t-height;
	width: $t-width;
	margin: 0;
	text-align: center;
	@include rollout;
	@media #{$small-up} {
		height: $t-height-small;
		width: $t-width-small;
	}
	&:hover {
		@include transform(scale(1.1));
		@include rollover;
	}
	a {
		&:link, &:visited {
			height: 100%;
			width: 100%;
			background: transparent url(../images/pro/T.svg) no-repeat 0 0;
			background-size: 100% 100%;
		}
	}
	&.t-cards {
		visibility: visible;
		opacity: 1;
	}
	&.t-epilogue {
		top: 50vh;
		transform: translateY(-50%);
		//margin-top: -($t-height / 2);
	}
}




