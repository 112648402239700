/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #COLORS
\*------------------------------------*/


//transporter
$transblau: #27A8E3; //2013
$transhellblau: lighten($transblau, 20%);
$transdunkelblau: darken($transblau, 10%);

.transhellblau { color: $transhellblau; }
.transdunkelblau { color: $transdunkelblau; }


//neutral
$schwarz: #000;
$sehrdunkelgrau: #4A4A4A;
$dunkelgrau: #9B9B9B;
$grau: #C8C8C8;
$hellgrau: #E3E3E3;
$sehrhellgrau: #F3F3F3;
$sehrsehrhellgrau: #F9F9F9;
$weiss: #FFF;


//brand
$farbe: $transblau;
$farbe2: $weiss;


//utility
$textfarbe: $weiss;

$blau: $farbe;
$gruen: #66CC00;
$pink: #FF00FF;
$orange: #FF7D00;
$nv: #d64933;


$primary-color: $farbe;
$secondary-color: $farbe2;
$success-color: $gruen;
$error-color: $orange;
$warning-color: $orange;
$code-color: #FF40FF;

$printbg: $grau;
$box-shadow-color: #999;

$debug-color: #FAC;





/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/


$margin-top: 0;
$margin-bottom: 2rem;
$margin-left: 0;
$margin-right: 0;

$padding: 1rem;


//global grid settings
$columns: 12;
$gutter-width: $padding * 2;
$column-width: 1000rem;//wird bei statischen gutters (gutter-position: inside-static) eigentlich nur als Platzhalter benötigt, https://github.com/ericam/susy/issues/368


$all-range: 	(0em, 29.9375em); /* 0, 479px */
$tiny-range: 	(30em, 39.9375em); /* 480px, 639px */
$small-range: 	(40em, 47.9375em); /* 640px, 767px */ //$small-range: (0em, 40em); /* 0, 640px */
$normal-range: 	(48em, 63.9375em); /* 768px, 1023px */
$medium-range: 	(64em, 79.9375em); /* 1024px, 1279px */ //$medium-range: (40.063em, 64em); /* 641px, 1024px */
$large-range: 		(80em, 89.9375em); /* 1280px, 1439px */
$xlarge-range: 		(90em, 99.9375em); /* 1440px, 1599px */
$xxlarge-range: 	(100em, 119.9375em); /* 1600px, 1919px */
$xxxlarge-range:	(120em); /* 1920px */


$screen: 		"only screen";

$landscape: 	"#{$screen} and (orientation: landscape)";
$portrait: 		"#{$screen} and (orientation: portrait)";

$all-up: $screen;
$all-only: 		"#{$screen} and (max-width:#{upper-bound($all-range)})";

$tiny-up: 		"#{$screen} and (min-width:#{lower-bound($tiny-range)})";
$tiny-only: 	"#{$screen} and (min-width:#{lower-bound($tiny-range)}) and (max-width:#{upper-bound($tiny-range)})";

$small-up: 		"#{$screen} and (min-width:#{lower-bound($small-range)})";
$small-only: 	"#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width:#{upper-bound($small-range)})";

$normal-up: 	"#{$screen} and (min-width:#{lower-bound($normal-range)})";
$normal-only: 	"#{$screen} and (min-width:#{lower-bound($normal-range)}) and (max-width:#{upper-bound($normal-range)})";

$medium-up: 	"#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only: 	"#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up: 		"#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: 	"#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up: 	"#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: 	"#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$xxlarge-up: 	"#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only: 	"#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

$xxxlarge-up: 	"#{$screen} and (min-width:#{lower-bound($xxxlarge-range)})";


//Legacy 
$all: $all-up;
$tiny: $tiny-up;
$small: $small-up;
$normal: $normal-up;
$medium: $medium-up;
$large: $large-up;
$xlarge: $xlarge-up;
$xxlarge: $xxlarge-up;
$xxxlarge: $xxxlarge-up;


$retina: "#{$screen} and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";






/*------------------------------------*\
    #BORDERS
\*------------------------------------*/


$border-width: 1px;
$border-style: solid;
$border-color: $hellgrau;

//runde ecken
$global-radius: 3px;
$global-rounded: 1000px;

//lines
$hr-border-width: $border-width;
$hr-border-style: $border-style;
$hr-border-color: $border-color;
$hr-margin: $margin-bottom;






/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/


$rem-base: 16;//17, 18, ...
$base-line-height: 1.4em;
$fontsmoothing: antialiased;//Values: none, antialiased (default), subpixel-antialiased


$body-font-family: "AvenirNextLTW01-Regular", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$body-font-weight: normal;
$body-font-style: normal;
$body-font-size: $rem-base + 0 + px;
$body-line-height: $base-line-height;


$body-font-family-bold: "Avenir Next LT W01 Bold", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$body-font-weight-bold: normal;

//$body-font-family-light: "Avenir Next W01 Thin", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
//$body-font-weight-light: normal;

$body-font-family-italic: $body-font-family;
$body-font-weight-italic: normal;

$small-font-size: 80%;


//mobile
$body-font-size-mobile: $rem-base - 1 + px;//$rem-base - 2 + px;//-1, -2, -3, ...
$webkit-tap-highlight-color: rgba(0, 0, 0, 0);//transparenter touch bg

//medium and large desktop
$body-font-size-medium-desktop: $rem-base + 2 + px;//-1, -2, -3, ...
$body-font-size-large-desktop: $rem-base + 4 + px;//-1, -2, -3, ...
$body-font-size-xxxlarge-desktop: $rem-base + 6 + px;//-1, -2, -3, ...


//absatz
$paragraph-font-family: $body-font-family;
$paragraph-font-weight: $body-font-weight;
$paragraph-font-style: $body-font-style;
$paragraph-font-size: font-size($rem-base);
$paragraph-font-color: $textfarbe;
$paragraph-line-height: $body-line-height;
$paragraph-top-margin: 0;
$paragraph-bottom-margin: $padding;


//header
$header-font-family: $body-font-family;
$header-font-weight: $body-font-weight;
$header-font-style: normal;
$header-font-color: $weiss;
$header-line-height: 1.3;
$header-top-margin: 0;
$header-bottom-margin: 1rem;

$subheader-font-family: $header-font-family;
$subheader-font-weight: $header-font-weight;
$subheader-font-style: $header-font-style;
$subheader-font-color: $grau;
$subheader-line-height: $header-line-height;
$subheader-top-margin: $header-top-margin;
$subheader-bottom-margin: $header-bottom-margin;

$h1-font-size: font-size($rem-base + 28);
$h2-font-size: font-size($rem-base + 20);
$h3-font-size: font-size($rem-base + 12);
$h4-font-size: font-size($rem-base + 8);
$h5-font-size: font-size($rem-base + 4);
$h6-font-size: font-size($rem-base);

$h1-font-color: $header-font-color;
$h2-font-color: $header-font-color;
$h3-font-color: $header-font-color;
$h4-font-color: $header-font-color;
$h5-font-color: $header-font-color;
$h6-font-color: $header-font-color;

$h1-line-height: 1.2;
$h2-line-height: 1.2;
$h3-line-height: $header-line-height;
$h4-line-height: $header-line-height;
$h5-line-height: $body-line-height;
$h6-line-height: $body-line-height;


//blockquote
$blockquote-background-color: $hellgrau;
$blockquote-border-style: $border-style;
$blockquote-border-size: $border-width;
$blockquote-border-color: $border-color;
$blockquote-font-color: $textfarbe;
$blockquote-padding: $padding;
$blockquote-cite-font-size: $small-font-size;
$blockquote-cite-font-color: $textfarbe;





/*------------------------------------*\
    #LINKS
\*------------------------------------*/


$anchor-text-decoration: none;
$anchor-font-color: $farbe;
$anchor-font-color-hover: $transdunkelblau;





/*------------------------------------*\
    #LISTS
\*------------------------------------*/


$list-style-position: outside;
$list-side-margin: 1.1rem;
$list-ordered-side-margin: 1.4rem;
$list-side-margin-no-bullet: 0;





/*------------------------------------*\
    #TABLES
\*------------------------------------*/


$table-bg: $weiss;

$table-border-style: $border-style;
$table-border-size: 0;
$table-border-color: $border-color;

$table-head-bg: $grau;
$table-head-font-size: font-size(16);
$table-head-font-color: $textfarbe;
$table-head-font-weight: normal;

$table-odd-row-bg: $weiss;
$table-even-row-bg: $sehrhellgrau;
$table-row-hover-bg: $hellgrau;
$table-row-padding: 0.5rem 1rem;
$table-row-font-size: font-size(16);
$table-row-font-color: $textfarbe;
$table-line-height: $body-line-height;





/*------------------------------------*\
    #ANIMATION
\*------------------------------------*/


$transition-duration: 0.25s;




