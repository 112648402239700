/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #MEDIA
\*------------------------------------*/


img, object, embed, video, iframe, figure {
	display: inline-block;
	height: auto;
	max-width: 100%;
	width: auto; /* for ie 8 */
	margin: 0 0 $margin-bottom 0;
}


//http://css-tricks.com/examples/SlideinCaptions
p.caption {
	margin-top: -($margin-bottom);
	font-size: $small-font-size;
	color: lighten($textfarbe,20%);
}


figure.caption {
	position: relative;
	overflow: hidden;
	line-height: 0;
	img { margin-bottom: 0; }
	figcaption {
		position: absolute;
		left: 0;
		right: 0;
		bottom: -30%;
		padding: ($padding * 2) $padding $padding $padding;
		font-size: $small-font-size;
		line-height: $paragraph-line-height;
		color: $weiss;
		@include background-image(linear-gradient(to bottom, rgba($schwarz,0) 0%, rgba($schwarz,0.5) 50%));
		opacity: 0;
		@include transition(all ($transition-duration * 2) ease-in);
		@include fontsmoothing(antialiased);
	}
	&:hover {
		cursor: help;
		figcaption {
			opacity: 1;
			bottom: 0;
			@include transition(all ($transition-duration * 2) ease-out);
		}
	}
}


//http://fitvidsjs.com


.fitvids { margin-bottom: $margin-bottom; }





