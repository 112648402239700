/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #LINES
\*------------------------------------*/


hr {
	height: 0;
	margin: 0 0 $hr-margin 0;
	background-color: none;
	border: 0;
	border-top: $hr-border-width $hr-border-style $hr-border-color;
}




