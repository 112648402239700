/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #ICONS
\*------------------------------------*/


//http://www.fontello.com


@charset "UTF-8";
$fontelloPath: "../fonts/fontello/fontello";
$icon-margin: 0.25em;

@include font-face("Fontello", "#{$fontelloPath}", $file-formats: ttf svg eot woff2 woff);


.icon-facebook:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\e801'; } /* '' */
.icon-bottom-open:before { content: '\e803'; } /* '' */
.icon-cancel:before { content: '\e804'; } /* '' */
.icon-download:before { content: '\e805'; } /* '' */
.icon-left-open:before { content: '\e806'; } /* '' */
.icon-plus:before { content: '\e807'; } /* '' */
.icon-right-open:before { content: '\e808'; } /* '' */
.icon-rocket:before { content: '\e809'; } /* '' */
.icon-tag:before { content: '\e80a'; } /* '' */
.icon-top-open:before { content: '\e80b'; } /* '' */


// mit diesem mixin können icons in sass inkludiert werden
// z.B. @include icon(attention, 1.5em, 0.5em, #F00, 0, 2px 2px 5px #FFF);
@mixin icon($type, $size: auto, $margin: auto, $color: auto, $top: auto, $shadow: none) {
	@extend %icon;
	&:before {
		@if $margin != auto { margin-right: $margin; }
		@if $size != auto { font-size: $size; }
		@if $top != auto { position: relative; top: $top; }
		@if $color != auto { color: $color; }
		@if $shadow != none { text-shadow: $shadow; }
	}
	@include icon-glyph($type);
}

@mixin icon-glyph($type) {
	@extend .icon-#{$type};
}

%icon {
	&:before {
		display: inline-block;
		width: 1em;
		margin-right: $icon-margin;
		/* Animation center compensation - margins should be symmetric, remove if not needed */
		//margin-left: $icon-margin;
		//vertical-align: middle;
		font-family: "Fontello";
		font-weight: normal;
		font-style: normal;
		text-decoration: none;
		speak: none;
		text-align: center;
		/* you can be more comfortable with increased icons size */
		//font-size: 1.2em;
		/* fix buttons height, for twitter bootstrap */
		line-height: 1em;
		//background-color: #fac;
		/* Uncomment for 3D effect */
		//text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
	}
 }


// generische icons per HTML-class, z.B. icon-hand
[class^="icon-"], [class*=" icon-"] {
	@extend %icon;
}
[class^="icon-large"], [class*=" icon-large"] {
	&:before {
		font-size: 1.5em;
		vertical-align: top;
	}
}


//überschreiben
.icon-margin-no:before { margin: 0; }
.icon-margin-right:before { margin-right: 0.5em; }
.icon-margin-left:before { margin-left: 0.5em; }






