/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/




//http://purecss.io/buttons


@import "../../../bower_components/pure-scss/buttons";


.pure-button, a.pure-button {
	display: inline-block;
	padding: ($padding / 2) $padding;
	//font-size: $body-font-size;//verhältnismässig größere buttons auf kleinen devices statt $body-font-size-mobile
	@include fontsmoothing(antialiased);
	color: $weiss;
	background-color: $primary-color;
	//@include single-text-shadow(0, 1px, 0, false, lighten($box-shadow-color,20%));
	border: $border-width solid transparent;
	border-radius: 0;//$global-radius;
	@include rollout;
	
	&:hover {
		filter: none;
		background-image: none;
		//@include boxshadow;
		@include rollover;
		background-color: darken($primary-color,10%);
	}
	
	&.round { border-radius: $global-rounded; }
	&.expand { display: block; }
	
	&.primary {
		background: $primary-color;
		&:hover { background-color: darken($primary-color,10%); }
	}
	&.secondary {
		color: $schwarz;
		background: $secondary-color;
		&:hover {
			color: $farbe;
			background-color: $secondary-color;
		}
	}
	
	&.success {
		background: $success-color;
		&:hover { background-color: darken($success-color,10%); }
	}
	&.error {
		background: $error-color;
		&:hover { background-color: darken($error-color,10%); }
	}
	&.warning {
		background: $warning-color;
		&:hover { background-color: darken($warning-color,10%); }
	}
	
	&.tiny { font-size: 70%; }
	&.small { font-size: 80%; }
	&.large { font-size: 120%; }
	&.xlarge { font-size: 140%; }
}


.pure-button[disabled], .pure-button-disabled, .pure-button-disabled:hover, .pure-button-disabled:focus, .pure-button-disabled:active {
	border: $border-width solid transparent;
	border-color: transparent;
}






