/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #LINKS
\*------------------------------------*/


a {
	&:link, &:visited {
		color: $anchor-font-color;
		text-decoration: $anchor-text-decoration;
		@include rollout;
	}
	&:hover {
		cursor: pointer;
		color: $anchor-font-color-hover;
		@include rollover;
		span { cursor: pointer; }
	}
	&:focus { outline: none; }
/*
	&[href^="http:"], &[href^="https:"] {
		&:before {
			//@mixin icon($type, $size: auto, $margin: auto, $color: auto, $top: auto, $shadow: none)
			@include icon(link-ext, 0.8em, 0.4em, auto, 0, none);
			margin-right: 0.4em;
			font-size: 0.8em;
		}
	}
*/
}


//textlink
a.textlink {
	&:link, &:visited {
		margin: 0;//0 -0.2em;
		padding: 0.1em 0.2em;
		background-color: lighten($anchor-font-color,45%);
		border-radius: 0;//$global-radius;
	}
	&:hover {
		color: $weiss;
		background-color: $anchor-font-color;
	}
	&:focus {}
	&[href^="http:"], &[href^="https:"] {}
}


//translink
a.translink {
	@extend .textlink;
	&:link, &:visited {
		color: $transblau;
		background-color: transparent;
	}
	&:hover {
		color: $weiss;
		background-color: $transblau;
	}
}


//img
a.img {
	position: relative;
	display: inline-block;
	line-height: 0;
	//border: 2px $border-style $farbe;
	@include transform(perspective(1000px) translate3d(0,0,0));
	
	&[href^="http:"], &[href^="https:"] {
		&:before {
			display: none;
			content: none;
		}
	}
	
	img {
		display: block;
		margin: 0;
		height: auto;
	}
	
	&:hover {
		background-color: transparent;
		@include boxshadow;
		@include transform(perspective(1000px) translate3d(0,0,50px));
		&:before {
			z-index: 10;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background: rgba($farbe,0.25);
			content: "";
		}
	}
}






