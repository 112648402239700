/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #TEXT
\*------------------------------------*/


//absatz
p {
	margin: $paragraph-top-margin 0 $paragraph-bottom-margin 0;
	font-size: $paragraph-font-size;
	font-family: $paragraph-font-family;
	font-weight: $paragraph-font-weight;
	font-style: $paragraph-font-style;
	line-height: $paragraph-line-height;
	color: $paragraph-font-color;
}


//headlines
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: $header-top-margin 0 $header-bottom-margin 0;
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	font-style: $header-font-style;
	line-height: $header-line-height;
	color: $header-font-color;
}

.subheader {
	margin: $subheader-top-margin 0 $subheader-bottom-margin 0;
	font-family: $subheader-font-family;
	font-weight: $subheader-font-weight;
	font-style: $subheader-font-style;
	//line-height: $subheader-line-height;
	color: $subheader-font-color;
}

h1, .h1 {
	font-size: $h1-font-size;
	line-height: $h1-line-height;
	color: $h1-font-color;
}
h2, .h2 {
	font-size: $h2-font-size;
	line-height: $h2-line-height;
	color: $h2-font-color;
}
h3, .h3 {
	font-size: $h3-font-size;
	line-height: $h3-line-height;
	color: $h3-font-color;
}
h4, .h4 {
	font-size: $h4-font-size;
	line-height: $h4-line-height;
	color: $h4-font-color;
}
h5, .h5 {
	font-size: $h5-font-size;
	line-height: $h5-line-height;
	color: $h5-font-color;
}
h6, .h6 {
	margin-bottom: 0;
	font-size: $h6-font-size;
	line-height: $h6-line-height;
	color: $h6-font-color;
}


.normal { @include font-normal; }
//.light, .duenn { @include font-light; }
b, strong, .bold, .fett { @include font-bold; }
.headline { @include font-header; }
i, em, .italic { @include font-italic; }


small, .small, .klein, sub, sup { font-size: $small-font-size; }


blockquote {
	margin: 0 0 $margin-bottom 0;
	padding: $blockquote-padding;
	color: $blockquote-font-color;
	background-color: $blockquote-background-color;
	border: $blockquote-border-size $blockquote-border-style $blockquote-border-color;
	p { margin-bottom: 0; }
	cite {
		font-size: $blockquote-cite-font-size;
		color: $blockquote-cite-font-color;
	}
}
cite {
	font-size: $blockquote-cite-font-size;
	color: $blockquote-cite-font-color;
}


address {
	margin: 0 0 $margin-bottom 0;
	font-style: normal;
}
del { color: $grau; }
dfn, var { font-family: $header-font-family; }
kbd {
	padding: 2px;
	box-shadow: #CCC 0 0 2px;
}
ins, mark {
	padding: 2px;
	background-color: $gruen;
	text-decoration: none;
}


code, samp {
	padding: 0.1em 0.2em;
	color: $code-color;
	background-color: $hellgrau;
	border-left: 4px solid $border-color;
}


abbr { color: inherit; font-size: 100%; }
abbr[title] { border-color: $farbe; }


//auswahl
::-moz-selection { background: $farbe; color: $weiss; text-shadow: none; }
::selection { background: $farbe; color: $weiss; text-shadow: none; }






