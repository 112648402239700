/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #LISTS
\*------------------------------------*/


ul, ol {
	margin: 0 0 $margin-bottom $list-side-margin;
	padding: 0;
	list-style-position: $list-style-position;
	
	&.disc { list-style-type: disc; }
	&.circle { list-style-type: circle; }
	&.square { list-style-type: square; }
	
	&.list-plain { @include list-plain; }
	&.list-inline { @include list-inline; }
	&.list-sidenav { @include list-sidenav; }
	&.list-buttongroup { @include list-buttongroup; }
	
	p:last-child { margin-bottom: 0; }
}

ol { margin-left: $list-ordered-side-margin; }

dl { margin-bottom: $margin-bottom }
dt {
	@include font-bold;
}
dd {
	margin: 0 0 $padding 0;
	&:last-child { margin-bottom: 0; }
}








