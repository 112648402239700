/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #GRID
\*------------------------------------*/


//http://susy.oddbird.net


@import "../../../bower_components/susy/sass/susy";


$susy: (
	flow: ltr,
	math: fluid,
	output: float,
	gutter-position: inside-static,/* after, */
	container: auto,
	container-position: center,
	columns: $columns,/* 4, */
	gutters: $gutter-width/$column-width,/* .25, */
	column-width: $column-width,/* false, */
	global-box-sizing: border-box,/* content-box, */
	last-flow: to,
	debug: (
		image: hide,
		color: rgba(#66f, .25),
		output: background,
		toggle: top right,
	),
	use-custom: (
		background-image: true,
		background-options: false,
		box-sizing: true,
		clearfix: false,
		rem: true,
	)
);

//grid classes generieren
@mixin susy-generator($query) {
	$i: 1;
	@for $i from 1 through $columns {
		.#{$query}-#{$i} {
			@include span($i);
		}
	}
}

//default row und columns
.row {
	@include clearfix;
	.row {
		max-width: none;
		margin-left: -(gutter());
		margin-right: -(gutter());
	}
}
.columns {
	@include span(12);
	min-height: 1px;
	//background: rgba(0,0,0,0.1);
	@include transition(all 0.5s ease-in-out);
}

[class*="all-"],
[class*="tiny-"],
[class*="small-"],
[class*="normal-"],
[class*="medium-"],
[class*="large-"],
[class*="xlarge-"],
[class*="xxlarge-"],
[class*="xxxlarge-"] {
	float: left;
	padding-left: $gutter-width / 2;
	padding-right: $gutter-width / 2;
}

//default grid und media-query grids generieren, beliebige ergänzen oder weglassen
@include susy-generator(all);
@media #{$tiny-up} 		{ @include susy-generator(tiny); }
@media #{$small-up} 	{ @include susy-generator(small); }
@media #{$normal-up} 	{ @include susy-generator(normal); }
@media #{$medium-up} 	{ @include susy-generator(medium); }
@media #{$large-up} 	{ @include susy-generator(large); }
@media #{$xlarge-up} 	{ @include susy-generator(xlarge); }
@media #{$xxlarge-up} 	{ @include susy-generator(xxlarge); }
@media #{$xxxlarge-up} 	{ @include susy-generator(xxxlarge); }





