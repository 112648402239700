/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #BODY
\*------------------------------------*/


$layout-h-padding: 5vw;
$layout-v-padding: 5vh;


html, body {
	height: 100%;
	min-height: 100vh;
	
	font-family: $body-font-family;
	font-weight: $body-font-weight;
	font-style: $body-font-style;
	font-size: $body-font-size-mobile;//kleine devices
	line-height: $body-line-height;
	
	color: $textfarbe;
	background-color: $schwarz;
	
	@include fontsmoothing($fontsmoothing);
	
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: $webkit-tap-highlight-color;
	
	display: flex;
	flex-direction: column;
}

body {}







