/*	TRANSPORTER
	Creating Your Web
	www.transporter.at
*/


/*------------------------------------*\
    #MIXINS
\*------------------------------------*/


//math
@function lower-bound($range) {
	@if length($range) <= 0 {
		@return 0;
	}
	@return nth($range,1);
}
@function upper-bound($range) {
	@if length($range) < 2 {
		@return 999999999999;
	}
	@return nth($range, 2);
}


//animation
@mixin rollout ($was: all) {
	@include transition($was $transition-duration ease-out);
}
@mixin rollover ($was: all) {
	@include transition($was ($transition-duration / 2) ease-out);
}


//position
@mixin fixed () {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
@mixin ir () {
	display: block;
	overflow: hidden;
	text-align: left;
	text-indent: -9999px;
	white-space: nowrap;
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0;
}

@mixin hidden () {
	//Completely remove from the flow and screen readers
	display: none !important;
	visibility: hidden !important;
}
@mixin visuallyhidden () {
	//Completely remove from the flow but leave available to screen readers
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


//layout
@mixin v-padding ($mypadding: $padding) {
	padding-top: $mypadding;
	padding-bottom: $mypadding;
}
@mixin h-padding ($mypadding: $padding) {
	padding-left: $mypadding;
	padding-right: $mypadding;
}

@mixin textcolumn ($spalten: 2) {
	-moz-column-count: $spalten;
	-webkit-column-count: $spalten;
	column-count: $spalten;
	-moz-column-gap: 1em;
	-webkit-column-gap: 1em;
	column-gap: 1em;
}

@mixin smooth-scroll() {
	-webkit-overflow-scrolling: touch;
}


//textcolor
@mixin textcolor($farbe_vom_text) {
	p, ul, ol, dl, h1, h2, h3, h4, h5, h6 {
		color: $farbe_vom_text;
		@if $farbe_vom_text == $weiss {
			@include fontsmoothing(antialiased);
		}
	}
}
@mixin headlinecolor($farbe_der_headline) {
	h1, h2, h3, h4, h5, h6 {
		color: $farbe_der_headline;
		@if $farbe_der_headline == $weiss {
			@include fontsmoothing(antialiased);
		}
	}
}
@mixin linkcolor($mouseout_farbe, $mouseover_farbe) {
	a:not(.pure-button) {
		&:link, &:visited {
			color: $mouseout_farbe;
/*
			@if $mouseout_farbe == $schwarz {
				border-color: $weiss;
			}
*/
		}
		&:hover, &:active {
			color: $mouseover_farbe;
		}
	}
}


//bg and shadow
@mixin retina-bg ($color, $file, $file-2x, $file-2x-width, $file-2x-height, $repeat, $xpos, $ypos) {
	//$color greift nur, wenn kein bild
	//$file ist "pfad zum bild" oder none
	//$file-2x ist "pfad zum @2x-bild" oder $bg-1-image
	//$file-2x-width und $file-2x-height für retina: tatsächliche pixel / 2
	background-color: $color;
	@if $file != none {
		background-image: url($file);
		background-repeat: $repeat;
		background-position: $xpos $ypos;
		@media #{$retina} {
			background-image: url($file-2x);
			background-size: $file-2x-width $file-2x-height;//tatsächliche pixel / 2
		}
	}
}
@mixin boxshadow ($farbe: $box-shadow-color) {
	box-shadow: $farbe 0 5px 10px;
}


//font
@mixin font-normal {
	font-family: $body-font-family;
	font-weight: $body-font-weight;
}
/*
@mixin font-light {
	font-family: $body-font-family-light;
	font-weight: $body-font-weight-light;
}
*/
@mixin font-bold {
	font-family: $body-font-family-bold;
	font-weight: $body-font-weight-bold;
}
@mixin font-header {
	font-family: $header-font-family;
	font-weight: $header-font-weight;
}
@mixin font-italic {
	font-family: $body-font-family-italic;
	font-weight: $body-font-weight-italic;
}

@function font-size($sizeValue: $rem-base) {
	//font-size: $sizeValue + px;
	//font-size: $sizeValue / $rem-base + rem;
	@return ($sizeValue / $rem-base + rem);
}

@mixin fontsmoothing ($value: antialiased) {
	//Values: none, antialiased (default), subpixel-antialiased
	@if $value == antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	@else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}


//list
@mixin list-plain {
	list-style-type: none;
	margin-left: $list-side-margin-no-bullet;
	padding: 0;
}
@mixin list-inline {
	list-style-type: none;
	margin-left: $list-side-margin-no-bullet;
	padding: 0;
	li {
		display: inline-block;
		padding: 0 $padding ($padding / 2) 0;
		line-height: 1em;
	}
}
@mixin list-sidenav {
	@include list-plain;
	li {
		 line-height: 2em;
		 border-bottom: $border-width $border-style $border-color;
		 &:last-child { border: none; }
	}
	a { display: block; }
}
@mixin list-buttongroup {
	list-style-type: none;
	margin-left: $list-side-margin-no-bullet;
	padding: 0;
	li {
		display: inline-block;
		padding: 0 ($padding / 4) ($padding / 4) 0;
		line-height: 1em;
	}
}





